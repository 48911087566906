import './App.css';
import axios from 'axios';
import logo from './IMG_4590.png'; 
import checker from './checker.svg'
import stato from './static.gif'
import bill from './bill.png'
import test from './test.svg'




import React, { useState } from 'react';


function App() {

  const [loaded, setLoaded] = useState(false);

  const [index, setIndex] = useState(0);

  const channels = ['https://www.youtube.com/embed/Lr_X_L-jRu8?si=mxOo39GzOVoLEG36'
  , 'https://www.youtube.com/embed/M4HVo6riOmM?si=3NHQjSEEyE2nIYAC'
  , 'https://www.youtube.com/embed/KGuCGd726RA?si=jniltwV0-sRiZpIl'
  , 'https://www.youtube.com/embed/TZ3mxF4U__k?si=jbAV6jWjNDHzGUEw'
  , 'https://www.youtube.com/embed/B4-L2nfGcuE?si=wTX5FCW1gfzITdmj'
  , 'https://www.youtube.com/embed/jfKfPfyJRdk?si=81-r1Ae3Tp7ZjU8J']

  const size = channels.length


  function delayLoad() {
    setTimeout(function(){
      setLoaded(true);
    }, 5000);
  }

  function changeChannel() {
    setLoaded(false);
    var temp = index;
    var neww = (temp + 1) % size
    setIndex(neww);
  }





  return (
    <div className="App">
      <header className="App-header">
        <img src={test} className='test'></img>
        <div className='left-header'>
            <div className='psy-box'>
                <p>PSY</p>
            </div>
            <div className='psy-box'>
              <p>PSY</p>
            </div>
        </div>
        <div className='mid-header'>
          <h1>PSY<i>KN</i>ICK</h1>
        </div>
        <div  className='right-header'>
          <div className='numberbox'>
            <p>31</p>
          </div>
        </div>
      </header>
      <div className='App-main'>
        <div>
        <div className='free-box-1'>
          <span className='free-box-txt-1'>KN</span>
        </div>
        <div className='free-box-2'>
          <span className='free-box-txt-2'>PSY</span>
        </div>
        <div className='top-row'>
          <span>+</span>
          <div className='button-space'>
            <button className='button'>
              portfolio
            </button>
            <button className='button' onClick={()=>changeChannel()}>
              im feeling lucky
            </button>
          </div>
          <span>+</span>
        </div>
        <div className='bottom-row'>
          <div className='leftbox'>
            <img src={checker} className='checker' id='left-check'/>
            <div className='text-outer-box'>
            <section>
              <h2>화요일</h2>
              <h3>verba ita sunt intelligenda ut res magis valeat quam pereat</h3>
            </section>
            <section>
              <h2>4월</h2>
              <h3>Neque porro quisquam est qui dolorem ipsum quia</h3>
            </section>
            <section>
              <h2>뉴욕시</h2>
              <h3>dolor sit amet, consectetur, adipisci velit</h3>
            </section>
            </div>
            <img src={bill} className='bill' onClick={()=>changeChannel()} ></img>  
          </div>
          <div className='outer-box' onClick={()=>changeChannel()}>
              <img src={logo} className='tv-box' alt="Logo" ></img>
              <img src={stato} className={loaded ? 'gone' : 'iframe'} id="z-lay"></img>
              <iframe 
                src={channels[index] + '?controls=0&showinfo=0&autoplay=1&mute=1&modestbranding=0'}
                frameborder='0'
                allow='"accelerometer; autoplay; encrypted-media'
                title='video'
                className='iframe'
                onLoad={()=>delayLoad()}
              />
          </div>
          <div className='rightbox'>
            <img src={checker} className='checker' />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default App;
